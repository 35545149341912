import { clientOnly, HttpHeader } from "@solidjs/start";

export default function AppClientOnlyWrapped() {
  const ClientOnlyApp = clientOnly(() => import("../classic_home"));
  return (
    <>
      <HttpHeader name="Content-Security-Policy" value="frame-ancestors *" />
      <ClientOnlyApp />
    </>
  );
}
